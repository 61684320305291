<!--
 * @Description: 首页
 * @Version: 1.0
 * @Autor: hekai
 * @Date: 2021-07-10 15:05:48
 * @LastEditors: hekai
 * @LastEditTime: 2021-07-10 15:06:24
-->
<template>
  <div>
    <div ref="main" id="dv-full-screen-container" class="main">
      <!--      <p-layout-scale>-->
      <div class="main-header">
        <div class="main-header-title">湾区认证全国分布情况概览
        </div>
      </div>
      <div
        style="position:absolute;right:20px;top:40px;color: #FFFFFF"
        :title="isFull?'退出全屏':'全屏展示'"
        @click="toFullOrExit">
        <div class="mr-5">
          <img :src="fullImg" alt="" style="cursor: pointer" width="25"/>
        </div>
      </div>
      <div class="main-container">
        <div class="main-left">
          <home-left @clickItem="clickItem"/>
        </div>
        <div class="main-middle">
          <home-middle @findArea="findArea"/>
        </div>
        <div class="main-right">
          <home-right
            v-if="flag"
            :rankUnitData="rankUnitData"
            :rankPersonData="rankPersonData"
            :caseHotData="caseHotData"
            :caseNewData="caseNewData"
            @findIconColor="findIconColor"/>
        </div>
      </div>
      <!--      </p-layout-scale>-->
    </div>
  </div>
</template>

<script>
  import PLayoutScale from '@comp/layout/PLayoutScale'
  import HomeLeft from '@views/general/left/HomeLeft'
  import HomeMiddle from '@views/general/middle/HomeMiddle'
  import HomeRight from '@views/general/right/HomeRight'

  export default {
    name: 'Home',
    components: { HomeRight, HomeMiddle, HomeLeft, PLayoutScale },
    data() {
      return {
        flag: false,
        rankUnitData: [],
        rankPersonData: [],
        caseNewData: [],
        caseHotData: [],
        isFull: false,
        fullImg: require('@/assets/bigscreen/quanping.png')
      }
    },
    mounted() {
      this.initData()
    },
    methods: {
      requestFullScreen() {
        let de = this.$refs.main
        if (de.requestFullscreen) {
          de.requestFullscreen()
        } else if (de.mozRequestFullScreen) {
          de.mozRequestFullScreen()
        } else if (de.webkitRequestFullScreen) {
          de.webkitRequestFullScreen()
        }
      },
      exitFullscreen() {
        let de = document
        if (de.exitFullscreen) {
          de.exitFullscreen()
        } else if (de.mozCancelFullScreen) {
          de.mozCancelFullScreen()
        } else if (de.webkitCancelFullScreen) {
          de.webkitCancelFullScreen()
        }
      },
      toFullOrExit() {
        this.isFull = !this.isFull
        if (this.isFull) {
          this.fullImg = require('@/assets/bigscreen/quxiaoquanping.png')
          this.requestFullScreen()
        } else {
          this.fullImg = require('@/assets/bigscreen/quanping.png')
          this.exitFullscreen()
        }
      },
      initData() {
        let list = []
        Promise.all(list).then(res => {
          this.flag = true
        })
      },
      findArea(val) {
        this.$emit('findArea', val)
      },
      clickItem(val) {
        this.$emit('clickItem', val)
      },
      findIconColor(val) {
        this.$emit('findIconColor', val)
      }
    }
  }
</script>

<style lang="less" scoped>
#dv-full-screen-container {
  position: relative;
  background: url("~@/assets/datav/background.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  font-family: Microsoft YaHei UI, serif;
  height: 100%;
  z-index: 0;
}

.main {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;

  @title-height: 92px;
  @margin-bottom: 10px;

  .main-header {
    width: 100%;
    height: 92px;
    margin-top: 12px;
    background-size: 100% 100%;
    font-family: Microsoft YaHei UI, serif;

    .main-header-title {
      height: @title-height;
      line-height: 60px;
      text-align: center;
      font-size: 36px;
      font-weight: bold;
      color: #0090FF;
      letter-spacing: 8px;
      background: linear-gradient(180deg, #FFFFFF 0%, #F6F7F6 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .main-container {
    width: 100%;
    height: calc(100% - @title-height);
    //margin-top: 20px; //TODO: 演示比例问题 暂时处理方法
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;

    .main-left {
      width: 23%;
      margin: 0 12px;
    }

    .main-middle {
      width: 52%;
    }

    .main-right {
      width: 25%;
      margin: 0 12px;
    }
  }

}
</style>
