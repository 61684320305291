<script>
  import EChart from '@comp/project/echarts/EChart.vue'
  import * as echarts from 'echarts'

  export default {
    name: 'LineChart',
    extends: EChart,
    props: {
      lineData: {
        type: Object,
        default: () => {
        }
      }
    },
    data() {
      return {
        option: {
          grid: {
            top: 35,
            left: 20,
            right: 20,
            bottom: 10,
            // 是否包含文本
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(9, 30, 60, 0.6)',
            borderWidth: 0,
            axisPointer: {
              lineStyle: {
                color: '#3763cd', // 显示竖线颜色
                type: 'solid'
              }
            },
            textStyle: {
              color: '#ffffff'
            }
          },
          legend: {
            show: true,
            top: '-5px',
            left: '70%',
            itemWidth: 8, // 图例图形宽度
            textStyle: {
              color: '#ffff',
              fontSize: 14,
              fontWeight: 'normal',
              padding: [0, 3],
              fontFamily: 'Microsoft YaHei'
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              // 坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: '#233653'
              }
            },
            splitLine: {
              show: true,
              interval: 0,
              lineStyle: {
                color: '#304d81',
                type: 'dashed'
              }
            },
            axisLabel: {
              show: true,
              interval: 0,
              textStyle: {
                color: '#b8c4d9',
                // padding: 10, //下方文字距离
                fontSize: 14
              },
              formatter: function (value) {
                if (value === 0) {
                  return value
                }
                return value
              }
            },
            axisTick: {
              show: false
            },
            data: [
              '1月',
              '2月',
              '3月',
              '4月',
              '5月',
              '6月',
              '7月',
              '8月',
              '9月',
              '10月',
              '11月',
              '12月'
            ]
          },
          yAxis: {
            // name: "单位：件",
            nameTextStyle: {
              color: '#7ec7ff',
              fontSize: '13px'
            // padding: [0, 0, 0, -50],
            },
            min: 0,
            splitLine: {
              show: false,
              lineStyle: {
                color: '#192a44'
              }
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#315182'
              }
            },
            axisLabel: {
              show: true,
              interval: 1,
              textStyle: {
                color: '#b8c4d9',
                padding: 10,
                fontSize: 14
              },
              formatter: function (value) {
                if (value === 0) {
                  return value
                }
                return value
              }
            },
            axisTick: {
              show: false
            }
          },
          series: [
            {
              name: '认证产品数量',
              type: 'line',
              // symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
              smooth: true,
              showSymbol: true, // 圆点显隐
              symbolSize: 5, // 圆点大小
              lineStyle: {
                normal: {
                  width: 4,
                  color: '#22e1db' // 线条颜色
                }
              },
              itemStyle: {
                color: '#b1fffd', // 圆角边框颜色
                borderWidth: 5
              },

              areaStyle: {
                // 区域填充样式
                normal: {
                  // 线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                  color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: 'rgba(34,225,219,1)'
                      },
                      {
                        offset: 1,
                        color: 'rgba(34,225,219,0)'
                      }
                    ],
                    false
                  ),
                  shadowColor: 'rgba(31,160,241,1)', // 阴影颜色
                  shadowBlur: 10 // shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                }
              },
              data: [12, 23, 24, 10, 19, 28, 26, 30, 0, 0, 0, 0]
            }
          ]
        }
      }
    },
    mounted() {
      this.initData()
    },
    watch: {
      lineData: {
        handler() {
          this.$nextTick(() => {
            this.setNameData(this.lineData)
          })
        },
        immediate: true,
        deep: true
      }
    },
    computed: {},
    methods: {
      setNameData(data) {
        this.option.xAxis.data = data.xData
        this.option.series[0].data = data.yData
        this.updateOption()
      }
    }
  }
</script>

<style lang='less' scoped>
</style>
