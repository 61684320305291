<template>
  <div class="border-bg">
    <div style="text-align: center;padding-top: 4px;position: relative;height: 100%">
      <div
        v-if="showTitle"
        class="box-title">
        {{ title }}
        <div class="extra">
          <slot name="extra"></slot>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PDvBorderBox10',
    props: {
      title: {
        type: String,
        default: ''
      },
      showTitle: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {}
    }

  }
</script>

<style lang="less" scoped>
.border-bg {
  //background: url("~@/assets/bigscreen/bor1_1.png") no-repeat;
  background-size: 100% 100%;
}

.extra {
  position: absolute;
  float: right;
  right: 20px;
  top: 12px;
}

.box-title {
  width: 100%;
  margin: 0 auto;
  letter-spacing: 2px;
  line-height: 45px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding-left: 50px;
}
</style>
