<script>
  import EChart from '@comp/project/echarts/EChart.vue'
  import * as echarts from 'echarts'

  export default {
    name: 'BarChart',
    extends: EChart,
    props: {
      nameData: {
        type: Array,
        default: () => []
      },
      numberData: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        option: {
          grid: {
            top: 35,
            left: 15,
            right: 15,
            bottom: 10,
            // 是否包含文本
            containLabel: true
          },
          legend: {
            show: false
          },
          tooltip: {
            // 触发类型  经过轴触发axis  经过轴触发item
            trigger: 'axis',
            backgroundColor: 'rgba(9, 30, 60, 0.6)',
            extraCssText: 'box-shadow: 0 0 8px rgba(0, 128, 255, 0.27) inset;',
            borderWidth: 0,
            confine: false,
            // appendToBody: true,
            textStyle: {
              color: '#fff',
              fontSize: 16,
              zIndex: 9999
            },
            // 轴触发提示才有效
            axisPointer: {
              type: 'shadow'
            },
            shadowStyle: {
              color: 'rgba(157, 168, 245, 0.1)'
            },

            formatter: (data) => {
              var tip = '<h5 style="color: #fff;font-size: 16px">' + data[0].name + '</h5>'
              data.forEach((item) => {
                let unit = ''
                if (item.seriesType === 'bar') {
                  tip += '<div class="echarts-tip-div">'
                  tip += '<div class="left">' + item.marker + item.seriesName + '：</div>'
                  tip += '<div class="right">' + item.value + '%' + '</div>'
                  tip += '</div>'
                }
              })
              return tip
            }
          },
          xAxis: {
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisLine: {
              lineStyle: {
                type: 'solid',
                color: '#4176a3',
                width: '0.5' // 坐标线的宽度
              }
            },
            axisLabel: {
              textStyle: {
                color: '#fff', // 底部文字颜色
                fontSize: 12
              }
            }
          },
          yAxis: {
            name: '单位: ' + '%',
            nameTextStyle: {
              align: 'left',
              fontSize: 11,
              color: '#4176a3'
            },
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: 'transparent' // 左边框颜色
              }
            },
            splitLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              show: true,
              fontSize: 12,
              textStyle: {
                color: '#ADD6FF' // 左文字颜色
              }
            }
          },
          series: [
            {
              name: '食材入库分类',
              type: 'bar',
              barWidth: 30,
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(21,136,209,0.1)'
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#1893FE' // 渐变1
                  },
                  {
                    offset: 1,
                    color: '#1EE3E8' // 渐变2
                  }
                ])
              },
              data: [20, 80, 100, 40, 34, 90, 60, 20, 80, 100, 40, 34],
              z: 0,
              zlevel: 0
            },
            {
              type: 'pictorialBar',
              barWidth: 30,
              itemStyle: {
                color: '#021C46' // 数据的间隔颜色
              },
              symbolRepeat: 'true',
              symbolMargin: 3,
              symbol: 'rect',
              symbolSize: [30, 4],
              data: [20, 80, 100, 40, 34, 90, 60, 20, 80, 100, 40, 34],
              z: 1,
              zlevel: 0,
              label: {
                show: true,
                position: 'top',
                fontSize: 14,
                color: '#fff', // 柱状顶部文字颜色
                formatter: function (params) {
                  return params.data
                }
              }
            }
          ]
        }
      }
    },
    mounted() {
      this.initData()
    },
    watch: {
      nameData: {
        handler() {
          this.$nextTick(() => {
            this.setNameData(this.nameData)
          })
        },
        immediate: true,
        deep: true
      },
      numberData: {
        handler() {
          this.$nextTick(() => {
            this.setNumberData(this.numberData)
          })
        },
        immediate: true,
        deep: true
      }
    },
    computed: {},
    methods: {
      setNameData(data) {
        /* if (data.length === 0) {
  return
} */
        /* this.option.yAxis.data = data */
        this.updateOption()
      },
      setNumberData(data) {
        /*  if (data.length === 0) {
   return
 } */
        /*  this.option.series[0].data = data */
        this.updateOption()
      }
    }
  }
</script>

<style lang='less' scoped>
</style>
