<!--
 * @Description:
 * @Version: 1.0
 * @Autor: Tt
 * @Date: 2021-11-26 15:50:45
-->
<template>
  <div class="scale-box" ref="sbox">
    <slot></slot>
  </div>
</template>


<script>
  export default {
    name: 'PLayoutScale',
    props: {
      designWidth: {
        type: Number,
        default: 1715
      },
      designHeight: {
        type: Number,
        default: 890//, 1000
      },
      // 偏移量，适配时特殊情况使用。需要自行在外部做动态计算
      offsetY: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        scale: '',
        designRatio: 9 / 16
      }
    },
    mounted() {
      this.initEvent()
    },
    beforeDestroy() {
      this.destoryEvent()
    },
    beforeUpdate() {
      // this.updateScale()
    },
    methods: {
      initEvent() {
        this.updateScale()
        window.addEventListener('resize', () => {
          this.updateScale()
        })
      },
      destoryEvent() {
      },
      updateScale() {
        let tbody = document.body
        let scaleBody = tbody.clientWidth / tbody.clientHeight// 16 : 7
        let scaleWH = 1920 / 1080 / scaleBody// 16:9
        // 第一步还原成1920到1080的形变比
        let scaleX = 1920 / this.designWidth
        let scaleY = 1080 / this.designHeight
        // 第二步计算形变比，使用宽度适配，将Y形变
        scaleY = scaleWH * scaleY// scaleX / scaleY *
        let offsetX = (1920 - this.designWidth) / 2// 100px
        let offsetY = (1080 * scaleWH - this.designHeight) / 2// 200px
        // //1030 根据宽高比进行一个形变， 需要进行计算，当前父级的宽高来进行推算。
        // // let screenWidth = tbody.clientWidth;
        // let screenHeight = tbody.clientHeight;
        // // this.height = screenHeight;
        // let screenWidth = this.width;
        // let screenHeight = this.height;
        // console.log("width,height|" + screenWidth + "," + screenHeight)
        // if (screenWidth == this.lastWidth) return;
        // this.lastWidth = screenWidth;

        // 形变分为x轴和y轴
        if (this.$refs.sbox) {
          this.$refs.sbox.style.setProperty('--scaleX', `${scaleX}`)
          this.$refs.sbox.style.setProperty('--scaleY', `${scaleY}`)
          this.$refs.sbox.style.setProperty('--offsetX', `${offsetX}px`)
          this.$refs.sbox.style.setProperty('--offsetY', `${offsetY}px`)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .scale-box {
    --scale: 1;
    --offsetX: -0px;
    --offsetY: -0px;
    /*暂时注释，拉伸影响布局*/
    /*transform: scale(var(--scaleX), var(--scaleY)) translate(var(--offsetX), var(--offsetY));*/
  }
</style>

