<template>
  <div
    :id="id"
    :class="className"
    :style="{ height:height,width:width}"
    class="word-chart"
    v-domresize="resizeCharts"/>
</template>

<script>
  import * as echarts from 'echarts'
  import 'echarts-wordcloud/dist/echarts-wordcloud'
  import 'echarts-wordcloud/dist/echarts-wordcloud.min'

  export default {
    props: {
      className: {
        type: String,
        default: 'chart_WordCloudChart'
      },
      id: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '400px'
      },
      title: {
        type: String,
        default: ''
      },
      list: {
        type: Array,
        default: () => {
          return []
        }
      },
      bgColor: {
        type: String,
        // 透明色
        default: 'rgba(255,255,255,0)'
      }
    },
    data() {
      return {
        chart: null
      }
    },
    mounted() {
      this.initChart()
    },
    beforeDestroy() {
      if (!this.chart) {
        return
      }
      this.chart.dispose()
      this.chart = null
    },
    watch: {
      list() {
        this.initChart()
      }
    },
    methods: {
      resizeCharts() {
        this.chart.resize()
      },
      initChart() {
        let self = this
        this.chart = echarts.init(document.getElementById(this.id))
        this.chart.on('click', function (params) {
          let data = params.data
          self.$emit('clickWord', data)
        })
        const option = {
          backgroundColor: this.bgColor,
          // tooltip: {
          //   pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
          // },
          series: [
            {
              type: 'wordCloud',
              // 用来调整词之间的距离
              gridSize: 6,
              // 用来调整字的大小范围
              // Text size range which the value in data will be mapped to.
              // Default to have minimum 12px and maximum 60px size.
              sizeRange: [12, 55],
              // Text rotation range and step in degree. Text will be rotated randomly in range [-90,                                                                             90] by rotationStep 45
              // 用来调整词的旋转方向，，[0,0]--代表着没有角度，也就是词为水平方向，需要设置角度参考注释内容
              // rotationRange: [-45, 0, 45, 90],
              // rotationRange: [-90, 0, 90],
              rotationRange: [0, 0],
              // 随机生成字体颜色
              // maskImage: maskImage,
              textStyle: {
                // fontFamily: '微软雅黑',
                color: function (params) {
                  let colors = [
                    '#ff326b', '#fd7d3d', '#1890ff',
                    '#fb64ff', '#a480ff', '#8cc187', '#ff0f52',
                    '#c1c14b', '#8cc187'
                  ]
                  return colors[params.dataIndex % colors.length]
                  // var colors = ['#fda67e', '#81cacc', '#cca8ba', '#88cc81', '#82a0c5', '#fddb7e', '#735ba1', '#bda29a', '#6e7074', '#546570', '#c4ccd3']
                  // return colors[parseInt(Math.random() * 10)]
                }
              },
              // 位置相关设置
              // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
              // Default to be put in the center and has 75% x 80% size.
              left: 'center',
              top: 'center',
              right: null,
              bottom: null,
              width: this.width,
              height: this.height,
              // 数据
              data: this.list
            }
          ]
        }
        this.chart.setOption(option)
      }
    }
  }
</script>
<style lang="less" scoped>
  .chartsClass {
    padding-left: 1.2rem;
  }

  .word-chart {
    display: flex;
    justify-content: center;
  }
</style>
