<script>
  import EChart from '@comp/project/echarts/EChart.vue'
  import * as echarts from 'echarts'
  import chinaCityJson from '@api/json/china.json'

  export default {
    name: 'MapChart',
    extends: EChart,
    props: {
      nameData: {
        type: Array,
        default: () => []
      },
      numberData: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        option: {}
      }
    },
    mounted() {
      let that = this
      echarts.registerMap('china', chinaCityJson)
      this.myChart = echarts.init(document.getElementById(this.id))
      this.myChart.setOption({
        geo: [{
          map: 'china'
        }]
      })
      // 监听点击事件
      this.myChart.on('click', function (params) {
        console.log(params.name) // 打印出点击区域的名称
        that.$emit('findArea', params.name)
      })
      this.initData()
    },
    watch: {
      nameData: {
        handler() {
          this.$nextTick(() => {
            this.setNameData(this.nameData)
          })
        },
        immediate: true,
        deep: true
      },
      numberData: {
        handler() {
          this.$nextTick(() => {
            this.setNumberData(this.numberData)
          })
        },
        immediate: true,
        deep: true
      }
    },
    computed: {},
    methods: {
      initData() {
        this.option = {
          visualMap: {
            show: false,
            type: 'continuous',
            calculable: true,
            left: '0%',
            bottom: '3%',
            dimension: 0, // 指定用数据的『哪个维度』，映射到视觉元素上。『数据』即 series.data。 可以把 series.data 理解成一个二维数组,其中每个列是一个维度,默认取 data 中最后一个维度
            seriesIndex: 1,
            itemWidth: 15, // 图形的宽度，即长条的宽度。
            itemHeight: 68,
            min: 0,
            // max: that.mapmax,
            // color: ['#01fffd', '#1E74FF'],
            // color: ['#0B64F3', '#1A05B8'],
            color: ['#007EF9 ', '#0B275E'],
            textStyle: {
              color: '#ccc',
              fontSize: 12
            }
          },
          tooltip: {
            trigger: 'item',
            padding: 8,
            backgroundColor: 'rgba(9, 30, 60, 0.6)',
            textStyle: {
              color: '#FFFFFF',
              fontSize: 14
            },
            formatter: (e) => {
              let data = e.data || e
              return `
                            <div>
                                <p><b style="font-size:15px;">${data.name}</b></p>
                                <p class="tooltip_style">
                                <span class="tooltip_left">认证企业数量：</span><span class="tooltip_right">${data.company || '暂无数据'}</span>
                                </p>
                                <p class="tooltip_style">
                                <span class="tooltip_left">获证产品总数：</span><span class="tooltip_right">${data.value || '暂无数据'}</span>
                                </p>
                                <p class="tooltip_style">
                                <span class="tooltip_left">证书数量：</span><span class="tooltip_right">${data.certificateNumber || '暂无数据'}</span>
                                </p>
                            </div>
                            `
            }
          },
          legend: {
            show: false,
            color: ['#5bffde'],
            icon: 'circle', // 只显示小圆点

            top: '10.7%',
            // bottom: '2.8%',
            left: '2%',
            itemGap: 22,
            itemWidth: 8, // 图例图形宽度
            textStyle: {
              color: '#ffff',
              fontSize: 14,
              fontWeight: 'normal',
              // align: "left",
              padding: [0, 3],
              fontFamily: 'Microsoft YaHei'
            },
            itemHeight: 8 // 改变圆圈大小
          },
          geo: {
            zoom: 1.6, // 缩放比例
            top: '30%',
            left: '25%',
            show: true,
            aspectScale: 0.85,
            silent: true, // 鼠标事件是否开启
            animation: true,
            map: 'china',
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false
              }
            },
            roam: false, // 是否可缩放
            //  aspectScale: 1, //长宽比
            // 这里是重点！！！
            regions: [
              {
                name: '南海诸岛',
                itemStyle: {
                  // 隐藏地图
                  normal: {
                    opacity: 0 // 为 0 时不绘制该图形
                  }
                },
                label: {
                  show: false // 隐藏文字
                }
              }
            ],

            itemStyle: {
              normal: {
                // areaColor: '#122C69', // #415ef7
                borderColor: '#122C69',
                shadowColor: '#122C69',
                shadowOffsetX: 1,
                shadowOffsetY: 9
              // borderWidth: 3,
              },
              emphasis: {
                areaColor: '#4499d0'
              }
            }
          },
          series: [
            {
              name: '背景地图',
              type: 'effectScatter',
              coordinateSystem: 'geo',
              symbolSize: function () {
                return 8
              },
              // data: mapData,
              data: [],
              rippleEffect: {
                period: 4,
                scale: 1.5,
                brushType: 'stroke'
              },
              label: {
                normal: {
                  formatter: '{b}',
                  position: 'bottom',
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              showEffectOn: 'render',
              hoverAnimation: true,
              itemStyle: {
                normal: {
                  color: '#5bffde' // 点的颜色
                },
                label: {
                  show: false
                }
              },
              zlevel: 1
            },
            {
              // 真的地图
              type: 'map',
              map: 'china',
              zoom: 1.6, // 缩放比例
              geoIndex: 1,
              left: '25%',
              top: '30%',
              aspectScale: 0.85, // 长宽比
              showLegendSymbol: true, // 存在legend时显示
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false,
                  textStyle: {
                    color: '#fff'
                  }
                }
              },
              roam: false,
              itemStyle: {
                normal: {
                  color: 'none',
                  areaColor: '#122C69', // '#01032a',
                  borderColor: '#246BD2', // 4065eb
                  borderWidth: 1
                },
                emphasis: {
                  areaColor: '#34d1fc'
                }
              },
              select: {
                // 选中时样式
                label: {
                  color: '#fff'
                },
                itemStyle: {
                  areaColor: '#34d1fc'
                }
              },
              animation: true,

              data: [
                {
                  name: '山东省',
                  value: 2,
                  certificateNumber: '2',
                  company: '2'
                },
                {
                  name: '江苏省',
                  value: 1,
                  certificateNumber: '1',
                  company: '1'
                },
                {
                  name: '广西壮族自治区',
                  value: 4,
                  certificateNumber: '4',
                  company: '4'
                },
                {
                  name: '广东省',
                  value: 82,
                  certificateNumber: '82',
                  company: '76'
                }
              ],
              zlevel: 1
            },
            {
              type: 'lines',
              zlevel: 1,
              effect: {
                show: true,
                period: 3, // 箭头指向速度，值越小速度越快
                trailLength: 0.02, // 特效尾迹长度[0,1]值越大，尾迹越长重
                symbol: 'arrow', // 箭头图标
                symbolSize: 10 // 图标大小
              },
              label: {
                emphasis: {
                  show: false
                }
              },
              lineStyle: {
                normal: {
                  width: 2, // 尾迹线条宽度
                  color: '#27C7E9',
                  opacity: 1, // 尾迹线条透明度
                  curveness: 0.3 // 尾迹线条曲直度
                }
              },
              data: [
                [
                  {
                    coord: ['113.746262', '23.046237'] // 跟上面的广东那一层的经纬度对应
                  },
                  { // 第一个起点的经纬度配置
                    coord: [111.57, 24.40],
                    value: '1',
                    certificateNumber: '1',
                    company: '1',
                    name: '贺州市'
                  }
                ],
                [
                  {
                    coord: ['113.746262', '23.046237'] // 跟上面的广东那一层的经纬度对应
                  },
                  { // 第一个起点的经纬度配置
                    coord: [110.18, 25.24],
                    value: '1',
                    certificateNumber: '1',
                    company: '1',
                    name: '桂林市'
                  }
                ],
                [
                  {
                    coord: ['113.746262', '23.046237'] // 跟上面的广东那一层的经纬度对应
                  },
                  { // 第一个起点的经纬度配置
                    coord: [111.28, 23.48],
                    value: '2',
                    certificateNumber: '2',
                    company: '2',
                    name: '梧州市'
                  }
                ],
                [
                  {
                    coord: ['113.746262', '23.046237'] // 跟上面的广东那一层的经纬度对应
                  },
                  { // 第二个起点的经纬度配置
                    coord: [118.80, 32.06],
                    value: '1',
                    certificateNumber: '1',
                    company: '1',
                    name: '南京市'
                  }
                ],
                [
                  {
                    coord: ['113.746262', '23.046237'] // 跟上面的广东那一层的经纬度对应
                  },
                  {
                    coord: [120.38, 36.07],
                    value: '2',
                    certificateNumber: '2',
                    company: '2',
                    name: '青岛市'
                  }
                ], [
                  {
                    coord: ['113.746262', '23.046237'] // 跟上面的广东那一层的经纬度对应
                  },
                  {
                    coord: [114.42, 23.11],
                    value: '5',
                    certificateNumber: '3',
                    company: '1',
                    name: '惠州市'
                  }
                ]
              ]
            }
          ]
        }
      },
      setNameData(data) {
        this.updateOption()
      },
      setNumberData(data) {
        this.updateOption()
      }
    }
  }
</script>

<style lang='less' scoped>
</style>
