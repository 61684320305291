<!--
 * @Description: PTabMenu 页签tab栏
 * @Version: 1.0
 * @Autor: hekai
 * @Date: 2021-10-22 18:57:23
 * @LastEditors: hekai
 * @LastEditTime: 2021-11-11 15:20:56
-->
<template>
  <div class="tab-body">
    <div class="tab-list">
      <div
        v-for="menuItem in tabList"
        :key="menuItem.key"
        :class="{ item: true, 'item-active': menuItem.key == value }"
        @click="selectMenu(menuItem)">
        {{ menuItem.tab }}
      </div>
    </div>
    <div class="tab-extra">
      <slot name="extra"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PTabMenu',
    props: {
      // [{key： 唯一标识符,tab 对应的文字显示}]
      tabList: {
        type: Array,
        require: true
      },
      value: {
        type: String
      }
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    methods: {
      selectMenu(menuItem) {
        this.$emit('change', menuItem.key)
      }
    }
  }
</script>

<style scoped lang="less">
  .tab-body {
    display: flex;
    justify-content: space-between;
    @height: 22px;

    .tab-list {
      display: flex;
      height: @height;

      .item {
        width: 86px;
        height: @height;
        line-height: @height;
        font-weight: bold;
        font-size: 14px;
        color: #BDC4CD;
        text-align: center;
        cursor: pointer;
      }

      .item-active {
        color: #fff;
        background: #105A85;
      }
    }

    .tab-extra {

    }

  }
</style>
