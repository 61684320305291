<template>
  <div :id="id" :style="{ width: width, height: height }" v-domresize="resizeCharts"></div>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid'
  import * as echarts from 'echarts'

  export default {
    // EChart 基类组件
    name: 'EChart',
    props: {
      title: {
        type: String,
        default: ''
      },
      height: {
        type: String,
        default: '250px'
      },
      width: {
        type: String,
        default: '100%'
      }
    },
    data() {
      return {
        id: '',
        myChart: null,
        option: {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: this.title,
              type: 'pie',
              radius: ['50%', '90%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
                lineHeight: 16,
                fontSize: 16
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '16',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: []
            }
          ]
        }
      }
    },
    created() {
      // 利用uuid生成动态id
      this.id = uuidv4().replaceAll('-', '')
    },
    mounted() {
      this.initData()
    },
    beforeDestroy() {
      this.destroy()
    },
    methods: {
      resizeCharts() {
        if (!this.myChart) {
          return
        }
        this.myChart.resize()
      },
      destroy() {
        this.myChart && this.myChart.dispose()
        this.myChart = null
      },
      initData() {
        this.destroy()
        // 初始化init
        this.myChart = echarts.init(document.getElementById(this.id))
        this.updateOption()
      },
      updateOption() {
        if (this.myChart) {
          this.myChart.clear()
          this.myChart.setOption(this.option, true)
        }
      }
    }
  }
</script>
