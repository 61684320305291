<script>
  import EChart from '@comp/project/echarts/EChart.vue'

  const lineStyle = 2
  export default {
    name: 'RadarChart',
    extends: EChart,
    props: {
      pieData: {
        type: Object,
        default: () => {
        }
      }
    },
    data() {
      return {
        option: {
          tooltip: {
            trigger: 'item',
            backgroundColor: 'rgba(9, 30, 60, 0.6)',
            borderWidth: 0,
            axisPointer: {
              lineStyle: {
                color: '#3763cd', // 显示竖线颜色
                type: 'solid'
              }
            },
            textStyle: {
              color: '#ffffff'
            }
          },
          radar: [
            {
              indicator: [
                { text: '工业消费品' },
                { text: '农食产品' },
                { text: '服务' },
                { text: '绿色建材' }
              ],
              center: ['50%', '50%'],
              radius: 65,
              axisName: {
                color: '#FFFFFF',
                fontWeight: 'bold'
              }
            }
          ],
          series: [
            {
              type: 'radar',
              name: '【认证类别】',
              data: [
                {
                  value: [5, 22, 5, 1]
                }
              ],
              itemStyle: {
                color: '#0CEAF8'
              }
            }
          ]
        }
      }
    },
    mounted() {
      this.initData()
    },
    watch: {
      pieData: {
        handler() {
          this.$nextTick(() => {
            this.setNumberData(this.pieData)
          })
        },
        immediate: true,
        deep: true
      }
    },
    computed: {},
    methods: {
      setNumberData(data) {
        this.option.series[0].data[0].value = data.data
        this.updateOption()
      }
    }
  }
</script>

<style lang='less' scoped>
</style>
